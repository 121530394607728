<template>
  <div>
    <b-row class="content-header mb-2">
      <!-- Content Left -->
      <b-col class="content-header-left" cols="12" md="9">
        <breadcrumb />
      </b-col>

      <!-- Content Right -->
      <b-col class="content-header-right text-md-right" md="3" cols="12">
        <b-button
          to="/events/create"
          class="text-capitalize px-3"
          variant="primary"
        >
          <feather-icon size="16" icon="PlusIcon" class="mr-50" />
          {{ $t("add event") }}
        </b-button>
      </b-col>
    </b-row>
    <div v-if="events">
      <div class="d-flex justify-content-between align-items-center">
        <per-page v-model="perPage" class="w-50" />

        <search ref="searchComponent" class="w-50" v-model="searchInput" />
      </div>
      <b-row class="my-2">
        <b-col
          v-for="event in events"
          :key="event.id"
          sm="12"
          md="6"
          lg="4"
          xl="3"
          cols="12"
        >
          <b-card class="mx-1"  no-body>
            <b-card-header style="height: 4rem">
              <b-card-title>{{ event.name.substring(0, 15) }}</b-card-title>
              <b-dropdown
                variant="link"
                toggle-class="p-0"
                no-caret
               right
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item
                  :disabled="isDisabled(event)"
                  :to="`/events/${event.id}/edit`"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50 text-capitalize">{{
                    $t("edit")
                  }}</span>
                </b-dropdown-item>
                <!-- <b-dropdown-item :to="{ name: 'apps-invoice-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item> -->
                <b-dropdown-item
                  :disabled="isDisabled(event)"
                  @click="showConfirmModal(event)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50 text-capitalize">{{
                    $t("delete")
                  }}</span>
                </b-dropdown-item>
                <b-dropdown-item @click="duplicate(event)">
                  <feather-icon icon="CopyIcon" />
                  <span class="align-middle ml-50 text-capitalize">{{
                    $t("duplicate")
                  }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-card-header>

            <b-img
              style="height: 16rem"
              fluid
              :src="event.logoUrl"
              alt="Card image cap"
            />
            <b-card-body class="d-flex flex-column justify-content-around">
              <div
                class="d-flex flex-column justify-content-between text-capitalize"
                style="height: 8rem"
              >
                <div>
                  <b>{{ $t("company") }}: </b> &nbsp;{{ event.company.name }}
                </div>
                <div>
                  <b>{{ $t("status") }}: </b>&nbsp; {{ event.status }}
                </div>
                <div>
                  <b>{{ $t("start date") }}: </b> &nbsp;{{
                    event.start_date.substring(0, 10)
                  }}
                </div>
                <div>
                  <b>{{ $t("end date") }}: </b>&nbsp;{{
                    event.end_date.substring(0, 10)
                  }}
                </div>
              </div>
              <b-link :to="`events/${event.id}/details`" class="card-link mt-2">
                {{ $t("Show details") }}...
              </b-link>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-card-group class="my-2"> </b-card-group>
      <pagination
        ref="paginationComponent"
        v-model="page"
        :paginationData="paginationData"
      />
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BAvatar,
  BButton,
  BCardText,
  BImg,
  BCardBody,
  BCardTitle,
  BCardHeader,
  BLink,
  BCardGroup,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import PerPage from "@/components/PerPage.vue";
import Search from "@/components/Search.vue";
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
    BAvatar,
    BButton,
    Pagination,
    Breadcrumb,
    BCardText,
    BImg,
    BCardBody,
    BCardTitle,
    BCardHeader,
    BLink,
    BCardGroup,
    BDropdown,
    BDropdownItem,
    PerPage,
    Search,
  },
  data() {
    return {
      perPage: 10,
      page: 1,
      events: null,
      paginationData: null,
      defaultAvatar: require("@/assets/images/avatars/default.png"),
      cancelToken: undefined,
      searchInput: "",
      sortBy: "",
      sortDir: "",
    };
  },

  mounted() {
    this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", false);
    this.getEvents();
  },
  watch: {
    searchInput(value) {
      this.page = 1;
      this.getEvents();
    },
    perPage(value) {
      this.page = 1;
      this.getEvents();
    },
    page(value) {
      this.getEvents();
    },
  },
  methods: {
    sort(sortBy, sortDir) {
      this.page = 1;
      this.sortBy = sortBy;
      this.sortDir = sortDir;
      this.getEvents();
    },
    async getEvents() {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      let data = {
        search: this.searchInput,
        per_page: this.perPage,
        page: this.page,
        sort_by: this.sortBy,
        sort_dir: this.sortDir,
      };

      const requestData = {
        method: "get",
        url: "events",
        params: data,
        cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
        .then((response) => {
          let data = response.data.data;
          this.events = data.data;
          this.paginationData = {
            current_page: data.meta.current_page,
            from: data.meta.from,
            to: data.meta.to,
            total: data.meta.total,
            per_page: data.meta.per_page,
            last_page: data.meta.last_page,
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showConfirmModal(event) {
      this.$bvModal
        .msgBoxConfirm(
          this.$t("Please confirm that you want to delete event") +
            `: ${event.name}.`,
          {
            title: this.$t("Please Confirm"),
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("Yes"),
            cancelTitle: this.$t("No"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (confirmed) => {
          if (confirmed) {
            let eventIndex = 0;
            this.events = await this.events.filter((eventItem, index) => {
              if (eventItem.id == event.id) {
                eventIndex = index;
              }
              return eventItem.id !== event.id;
            });

            const requestData = {
              method: "delete",
              url: `events/${event.id}`,
            };
            this.$http(requestData)
              .then((response) => {
                this.$bvToast.toast(this.$t("Event deleted successfully"), {
                  title: this.$t("Done"),
                  variant: "success",
                  solid: true,
                });
              })
              .catch((err) => {
                let message = this.$t("Unable to delete!");
                if (
                  err.response &&
                  err.response.data &&
                  err.response.data.message
                )
                  message = err.response.data.message;
                this.$bvToast.toast(message, {
                  title: this.$t("Error"),
                  variant: "danger",
                  solid: true,
                });
                this.events.splice(eventIndex, 0, event);
              });
          }
        });
    },
    isDisabled(event) {
      return event.status !== "draft";
    },
    duplicate(event) {
      const requestData = {
        method: "post",
        url: `events/${event.id}/duplicate`,
      };
      this.$http(requestData)
        .then((response) => {
          this.$refs.paginationComponent.clear();
          this.$refs.searchComponent.clear();
          this.page = 1;
          this.searchInput = "";
          this.getEvents();
          this.$bvToast.toast(this.$t("Event duplicated successfully"), {
            title: this.$t("Done"),
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          let message = this.$t("Unable to duplicate!");
          if (err.response && err.response.data && err.response.data.message)
            message = err.response.data.message;
          this.$bvToast.toast(message, {
            title: this.$t("Error"),
            variant: "danger",
            solid: true,
          });
        });
    },
  },
};
</script>

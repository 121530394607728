<template>
  <div>
    <b-row>
      <b-col class="pt-2" md="12" xl="6">
        <!-- <b-form-group
          class="px-2 px-lg-5"
          label="Name"
          label-for="nameInput"
        >
          <b-form-input
            placeholder="Name of the brand"
            v-model="eventInput.branding_name"
            id="nameInput"
          />
        </b-form-group> -->

        <b-form-group class="mt-2 px-2 px-lg-5" label="Tags" label-for="">
          <v-select
            :components="{ OpenIndicator }"
            class="bg-white"
            :clearable="false"
            v-model="tags"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            taggable
            multiple
            :closeOnSelect="false"
            placeholder="Type and enter to add tags"
          />
        </b-form-group>
      </b-col>
      <b-col class="pt-2" md="12" xl="6">
        <div class="p-2 px-lg-5">
          <span class="text-capitalize">{{ $t("logo") }}:</span>
          <file-picker
            class=""
            ref="logoPicker"
            :url="eventInput.logoUrl"
            v-model="logoFile"
          />
        </div>
        <div class="p-2 px-lg-5">
          <span class="text-capitalize">{{ $t("background") }}:</span>
          <file-picker
            class=""
            ref="backgroundPicker"
            :url="eventInput.backgroundUrl"
            v-model="backgroundFile"
          />
        </div>
      </b-col>

      <b-col class="pt-2 d-flex" md="12" xl="6">
        <div class="px-2">
          <b-form-group class=" ">
            <b-form-checkbox v-model="eventInput.dark_mode">
              <span class="fontSize1Rem font-weight-bold text-capitalize">{{
                $t("dark mode")
              }}</span>
            </b-form-checkbox>
          </b-form-group>
          <b-form-group
            class="mt-2"
            :label="$t('Primary Color')"
            label-for="PrimaryColorInput"
          >
            <chrome class="" v-model="eventInput.primary_color" />
          </b-form-group>
          <b-form-group
            class="mt-2"
            :label="$t('Secondary Color')"
            label-for="SecondaryColorInput"
          >
            <chrome class="" v-model="eventInput.secondary_color" />
          </b-form-group>
        </div>
        <div class="px-2">
          <div class="mb-1 fontSize1-1Rem font-weight-bold text-capitalize">
            {{ $t("application preview") }}
          </div>
          <div class="applicationPreview">
            <div
              class="app_header"
              :style="{
                backgroundColor:
                  eventInput.primary_color.hex || eventInput.primary_color,
              }"
            >
              <feather-icon
                size="16"
                icon="ArrowLeftIcon"
                :color="previewTextColor"
              />
              <img :src="previewImage" height="40" />
              <b-avatar :src="require('@/assets/images/avatars/1-small.png')" />
            </div>
            <div
              class="app_tabs"
              :style="{
                backgroundColor:
                  eventInput.primary_color.hex || eventInput.primary_color,
                color: previewTextColor,
              }"
            >
              <div
                class="app_tab_item mr-1 active text-capitalize"
                :style="{
                  borderBottomColor:
                    eventInput.secondary_color.hex ||
                    eventInput.secondary_color,
                }"
              >
                {{ $t("waiting list") }} (3)
              </div>
              <div class="app_tab_item">{{ $t("testing") }} (5)</div>
            </div>

            <div class="app_body">
              <div
                class="app_card"
                :style="{
                  backgroundColor:
                    eventInput.primary_color.hex || eventInput.primary_color,
                }"
                v-for="(previewCard, index) in previewCards"
                :key="'previewCard' + index"
              >
                <div>
                  <div
                    class="app_card_title"
                    :style="{ color: previewTextColor }"
                  >
                    {{ previewCard.title }}
                  </div>
                  <div class="app_card_sub_title">
                    {{ previewCard.subTitle }}
                  </div>
                </div>
                <div>
                  <feather-icon
                    size="16"
                    icon="ChevronRightIcon"
                    :color="
                      eventInput.secondary_color.hex ||
                      eventInput.secondary_color
                    "
                  />
                </div>
              </div>
            </div>
            <div
              class="app_footer"
              :style="{
                backgroundColor:
                  eventInput.primary_color.hex || eventInput.primary_color,
              }"
            >
              <div
                class="d-flex justify-content-center align-items-center flex-column"
              >
                <feather-icon
                  size="25"
                  icon="UsersIcon"
                  :color="
                    eventInput.secondary_color.hex || eventInput.secondary_color
                  "
                />
                <span
                  class="fontSize1-8Rem mt-xl-n50"
                  style="height: 0.5rem"
                  :style="{
                    color:
                      eventInput.secondary_color.hex ||
                      eventInput.secondary_color,
                  }"
                  >&#8226;</span
                >
              </div>

              <feather-icon
                size="25"
                icon="PackageIcon"
                :color="eventInput.dark_mode ? '#fff' : ''"
              />
              <feather-icon
                size="25"
                icon="FlagIcon"
                :color="eventInput.dark_mode ? '#fff' : ''"
              />
            </div>
            <div
              class="add_btn d-flex justify-content-center align-items-center"
              :style="{
                backgroundColor:
                  eventInput.secondary_color.hex || eventInput.secondary_color,
              }"
            >
              <feather-icon size="25" icon="PlusIcon" color="#fff" />
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-right" cols="12">
        <b-button
          class="text-capitalize"
          @click="$emit('goBack')"
          variant="outline-primary"
        >
          {{ $t("back") }}
        </b-button>
        <b-button @click="submitForm()" class="mx-1 px-3" variant="primary">
          <div v-if="submitLoading" class="px-1">
            <b-spinner small style="width: 1.3rem; height: 1.3rem" />
            <span class="sr-only">Loading...</span>
          </div>
          <span class="text-capitalize" v-else>
            {{ $t("save & next") }}
          </span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormFile,
  BSpinner,
  BFormCheckbox,
  BAvatar,
} from "bootstrap-vue";
import FilePicker from "@/components/FilePicker.vue";
import vSelect from "vue-select";
import { Chrome } from "vue-color";
export default {
  props: ["event", "step"],
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    BFormFile,
    Chrome,
    BSpinner,
    FilePicker,
    BFormCheckbox,
    BAvatar,
  },
  data() {
    return {
      previewCards: [
        {
          title: "Bannon Morrissy",
          subTitle: "Model : Kia Model Sportage",
        },
        {
          title: "Rana sawallha",
          subTitle: "Model : Kia Model Sorento",
        },
        {
          title: "Pitter Smith",
          subTitle: "Model : Kia Model EV6",
        },
        {
          title: "Edward Keyn",
          subTitle: "Model : Kia Model Stinger",
        },
      ],
      OpenIndicator: {
        render: (createElement) =>
          createElement("feather-icon", { attrs: { icon: "ChevronDownIcon" } }),
      },
      submitLoading: false,
      logoFile: null,
      backgroundFile: null,
      eventInput: {
        id: null,
        branding_name: "",
        logo: null,
        primary_color: "#194d33",
        secondary_color: "#0B0C85",
        tagsInput: "",
        step: "",
        dark_mode: false,
      },
      tags: [],
      editMode: false,
      cancelToken: undefined,
    };
  },
  computed: {
    previewImage() {
      if (this.logoFile) {
        return URL.createObjectURL(this.logoFile);
      }

      if (this.eventInput.logoUrl) return this.eventInput.logoUrl;

      return null;
    },
    previewTextColor() {
      return this.eventInput.dark_mode ? "#fff" : "#000";
    },
  },
  // watch: {
  //   eventInput: {
  //     handler(val) {
  //       this.$emit("setEvent", { ...this.eventInput, ...this.event });
  //     },
  //     deep: true,
  //   },
  // },
  mounted() {
    if (this.event) {
      if (!this.event.primary_color) this.event.primary_color = "#194d33";
      if (!this.event.secondary_color) this.event.secondary_color = "#0B0C85";
      // this.eventInput.id = this.event.id;
      this.eventInput = { ...this.event };
      if (this.eventInput.tagsInput) {
        if (typeof this.eventInput.tagsInput == "string") {
          try {
            this.tags = JSON.parse(this.eventInput.tagsInput);
          } catch (error) {
            this.tags = this.event.tagsInput.split(",");
          }
        } else {
          this.tags = this.eventInput.tagsInput;
        }
      } else {
        this.tags = [];
      }
    }
    this.eventInput.step = this.step;
    // this.getTags("", false);
  },
  methods: {
    submitForm() {
      if (this.submitLoading) return;
      let errorMessage = null;
      // if (!errorMessage && !this.eventInput.branding_name) {
      //   errorMessage = "Please enter branding name";
      // }

      if (!errorMessage && !this.eventInput.logo && !this.logoFile) {
        errorMessage = this.$t("Please select branding logo");
      }

      if (
        !errorMessage &&
        !this.eventInput.background &&
        !this.backgroundFile
      ) {
        errorMessage = this.$t("Please select background");
      }

      if (errorMessage) {
        this.$emit("showToast", { variant: "danger", message: errorMessage });

        return;
      }

      this.submitLoading = true;
      if (typeof this.tags == "string")
        this.eventInput.tagsInput = this.tags.split(", ");
      else this.eventInput.tagsInput = this.tags;

      // if(!this.eventInput.tagsInput)
      let formData = new FormData();

      if (this.logoFile) formData.append("logoFile", this.logoFile);

      if (this.backgroundFile)
        formData.append("backgroundFile", this.backgroundFile);

      for (const [key, value] of Object.entries({
        ...this.eventInput,
        // ...this.event,
      })) {
        if (key == "tagsInput") {
          if (value && value.length > 0) formData.append(key, value);
        } else {
          if (value && value.hex) formData.append(key, value.hex);
          else formData.append(key, value);
        }
      }
      const requestData = {
        method: "post",
        url: `events/${this.event.id}/update`,
        // data: { ...this.eventInput, ...this.event },
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          enctype: "multipart/form-data",
        },
      };

      this.$http(requestData)
        .then((response) => {
          let message = this.$t("Event branding updated successfully.");
          this.$emit("showToast", { variant: "success", message });

          this.submitLoading = false;
          // this.$emit("setEvent", { ...this.eventInput });
          this.$emit("addPassedStep", this.step);
          this.$emit("setEvent", response.data.data);
          this.$emit("goNext");
        })
        .catch((err) => {
          let message = this.$t("Unable to update event branding.");
          if (err.response && err.response.data && err.response.data.message)
            message = err.response.data.message;
          this.$emit("showToast", { variant: "danger", message });

          this.submitLoading = false;
        });
    },
    getTags(search, loading) {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      let data = {
        search: search,
        per_page: 10,
        page: 1,
      };

      const requestData = {
        method: "get",
        url: "tags",
        params: data,
        cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
        .then((response) => {
          let data = response.data.data;
          this.tags = data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.applicationPreview {
  position: relative;
  width: 350px;
  height: 700px;
  // padding:10px;
  border: 1px solid #868686;
  border-radius: 10px;
  .app_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px;
    border: 1px solid transparent;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .app_tabs {
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    .app_tab_item {
      &.active {
        border-bottom: 2px solid;
      }

      padding-bottom: 5px;
      width: 130px;
      text-align: center;
    }
  }
  .app_body {
    background: #f2f5f8;
    height: 550px;
    padding: 10px;

    .app_card {
      background: white;
      height: 75px;
      margin-top: 20px;
      width: 100%;
      border: 1px solid transparent;
      box-shadow: 0px 2px 48px rgba(0, 0, 0, 0.04);
      border-radius: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      .app_card_title {
        color: black;
        margin-bottom: 5px;
      }
      .app_card_sub_title {
        color: #86868d;
      }
    }
  }
  .app_footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 86px;
    width: 100%;
    background: #fff;
    /* Shadow 1 */

    box-shadow: 0px -6px 16px rgba(65, 100, 143, 0.14);
    border-radius: 35px 35px 10px 10px;
    border: 1px solid transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
  }
  .add_btn {
    position: absolute;
    height: 65px;
    width: 65px;
    bottom: 100px;
    right: 10px;
    border: 1px transparent;
    border-radius: 100%;
  }
}
</style>
